import CurrencyFormatter from 'src/components/ui/currency-formatter'
import * as React from 'react'
import { IAuction } from 'src/_models/auction.model'
import { BUYER_FEES_CALC, FUEL_FEES_CALC } from 'src/lib/constants'
import { Dispatch, SetStateAction, useEffect } from 'react'

const AuctionDialogFeesCalculation = ({
  cents,
  auction,
  setTotal,
  bidAmount,
}: {
  cents?: boolean
  auction: IAuction
  bidAmount: number
  setTotal: Dispatch<SetStateAction<number>>
}) => {
  const shouldBeDivided = cents ? 1 : 100
  const stateTaxes = bidAmount ? (bidAmount * +auction.stateTaxPercentage) / 100 : 0
  const cityTaxes = bidAmount ? (bidAmount * (auction.cityTaxPercentage ? +auction.cityTaxPercentage : 0)) / 100 : 0
  const buyerFees = bidAmount ? bidAmount * BUYER_FEES_CALC : 0
  const fuelFeesFixed = bidAmount ? (auction.fuelFeesFixed ? +auction.fuelFeesFixed / shouldBeDivided : 0) : 0
  const fuelFeesPercentage = bidAmount
    ? (bidAmount * (auction.fuelFeesPercentage ? +auction.fuelFeesPercentage : 0)) / 100
    : 0

  const otherFees = auction.auctionOtherFees.map((o) => ({
    label: o.label,
    value: o.isPercentage ? (bidAmount * +o.value) / 100 : +o.value / shouldBeDivided,
  }))

  useEffect(() => {
    const totalOtherFees = otherFees.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)
    setTotal(bidAmount + cityTaxes + stateTaxes + buyerFees + fuelFeesFixed + fuelFeesPercentage + totalOtherFees)
  }, [bidAmount])

  return (
    <>
      <div className="mb-[10px] flex w-full items-center justify-between">
        <p className=" block text-sm text-secoundary 2xl:text-base">State Taxes</p>
        <p className=" block text-sm text-secoundary 2xl:text-base">
          <CurrencyFormatter currency={auction.sellingCurrency} amount={stateTaxes} cents={cents} />
        </p>
      </div>
      <div className="mb-[10px] flex w-full items-center justify-between">
        <p className=" block text-sm text-secoundary 2xl:text-base">City Taxes</p>
        <p className=" block text-sm text-secoundary 2xl:text-base">
          <CurrencyFormatter currency={auction.sellingCurrency} amount={cityTaxes} cents={cents} />
        </p>
      </div>
      <div className="mb-[10px] flex w-full items-center justify-between">
        <p className=" block text-sm text-secoundary  2xl:text-base">Buyer Fees</p>
        <p className=" block text-sm text-secoundary 2xl:text-base">
          <CurrencyFormatter currency={auction.sellingCurrency} amount={buyerFees} cents={cents} />
        </p>
      </div>
      <div className="mb-[10px] flex w-full items-center justify-between">
        <p className=" block text-sm text-secoundary  2xl:text-base">Fuel Fees (Fixed)</p>
        <p className=" block text-sm text-secoundary 2xl:text-base">
          <CurrencyFormatter currency={auction.sellingCurrency} amount={fuelFeesFixed} cents={cents} />
        </p>
      </div>
      <div className="mb-[10px] flex w-full items-center justify-between">
        <p className=" block text-sm text-secoundary  2xl:text-base">Fuel Fees (Percentage)</p>
        <p className=" block text-sm text-secoundary 2xl:text-base">
          <CurrencyFormatter currency={auction.sellingCurrency} amount={fuelFeesPercentage} cents={cents} />
        </p>
      </div>
      {otherFees.map((o, index) => {
        return (
          <div key={index} className="mb-[10px] flex w-full items-center justify-between">
            <p className=" block text-sm text-secoundary  2xl:text-base">{o.label}</p>
            <p className=" block text-sm text-secoundary 2xl:text-base">
              <CurrencyFormatter currency={auction.sellingCurrency} amount={o.value} cents={cents} />
            </p>
          </div>
        )
      })}
    </>
  )
}

export default AuctionDialogFeesCalculation

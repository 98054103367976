import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { z, ZodType } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import { toast } from 'src/components/ui/use-toast'

type IForgetPasswordForm = {
  email: string
}

type ISendResetPasswordVerificationProps = {
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  goForward: () => void
}

export default function SendResetPasswordVerification({
  email,
  setEmail,
  goForward,
}: ISendResetPasswordVerificationProps) {
  const { t } = useTranslation('translation')

  const forgetPasswordSchema: ZodType<IForgetPasswordForm> = z.object({
    email: z.string({ required_error: t('error.emailRequired') }).email(t('error.emailInvalid')),
  })

  const { control, handleSubmit } = useForm<IForgetPasswordForm>({
    defaultValues: {
      email: email,
    },
    resolver: zodResolver(forgetPasswordSchema),
  })

  const forgetPasswordMutation = useMutation({
    mutationFn: (data: IForgetPasswordForm): Promise<AxiosResponse<{ success: boolean }>> => {
      return axios.post('/api/auth/forgetPassword', data)
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
  })

  const sendForgetPasswordInstructions = (data: IForgetPasswordForm) => {
    forgetPasswordMutation.mutate(data, {
      onSuccess: (_) => {
        setEmail(data.email)
        toast({ title: 'Please verify code sent to email.' })
        goForward()
        // redirect('/auth/verification')
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        if (error.response?.status === 401) {
          toast({ title: t('login.incorrectCredentialError') })
        } else {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || 'error.somethingWrong'),
          })
        }
      },
    })
  }

  return (
    <>
      <h1 className="pb-10 text-2xl font-bold text-txtblack">{t('forgetPassword.heading')}</h1>

      <form className="" onSubmit={handleSubmit(sendForgetPasswordInstructions)} noValidate={true}>
        <div className="pb-5">
          <label htmlFor="email" className="block text-sm font-bold leading-6 text-black">
            {t('login.emailLabel')}
          </label>

          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => {
              return (
                <div className="mt-2">
                  <Input
                    id="email"
                    type="email"
                    autoComplete="email"
                    placeholder={t('login.emailPlaceholder')}
                    error={fieldState.error}
                    {...field}
                  />
                </div>
              )
            }}
          />
        </div>

        <div className="flex gap-4 pt-10">
          {/*<Button type="button" expand="full" onClick={() => showModal(true)}>*/}
          {/*  {t('login.signUpBtn')}*/}
          {/*</Button>*/}
          <Button disabled={forgetPasswordMutation.isLoading} expand="full" type="submit">
            {t('forgetPassword.sendVerificationCode')}
          </Button>
        </div>
      </form>
    </>
  )
}

import { useState } from 'react'
import { ColumnsOrderCompleted, columnsOrderCompleted } from 'src/components/columns'
import FilterComponent from 'src/components/common/filter'
import { DataTable } from 'src/components/data-table'

async function getData(): Promise<ColumnsOrderCompleted[]> {
  // Fetch data from your API here.
  return [
    {
      id: '1',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '85%',
      price: '$ 20',
      quantity: '250 kg',
      buyer_name: 'Hydrogen Purification Co.',
      bid_amount: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '2',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Green',
      purity_level: '99%',
      price: '$ 20',
      quantity: '250 kg',
      buyer_name: 'Hydrogen Purification Co.',
      bid_amount: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '3',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Gray',
      purity_level: '85%',
      price: '$ 20',
      quantity: '100 kg',
      buyer_name: 'Hydrogen Purification Co.',
      bid_amount: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '4',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Gray',
      purity_level: '85%',
      price: '$ 20',
      quantity: '100 kg',
      buyer_name: 'Hydrogen Purification Co.',
      bid_amount: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '5',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      price: '$ 20',
      quantity: '250 kg',
      buyer_name: 'Hydrogen Purification Co.',
      bid_amount: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '6',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      price: '$ 20',
      quantity: '400 kg',
      buyer_name: 'Hydrogen Purification Co.',
      bid_amount: '$ 3,000.00',
      action: 'View Order',
    },
    {
      id: '7',
      order_date: '12·04·23',
      order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      price: '$ 20',
      quantity: '250 kg',
      buyer_name: 'Hydrogen Purification Co.',
      bid_amount: '$ 3,000.00',
      action: 'View Order',
    },
  ]
}
const data = await getData()

const RecOrderCompleted = () => {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  return (
    <>
      <section className="w-full">
        <div className="container mx-auto bg-transparent ">
          <div className="mb-[15px] flex w-full items-center justify-between pt-10 align-middle ">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Completed Orders</h2>
            <span className="relative text-sm">
              <div className="flex gap-[10px]">
                <button
                  onClick={() => setShowFilter(!showFilter)}
                  className="w-[102px] rounded-[10px] bg-white px-[15px] py-[8px] text-center align-middle text-xs font-medium text-secoundary shadow-md 2xl:text-sm"
                >
                  Filter By
                </button>
                <button className="rounded-[10px] text-center align-middle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 10 13" fill="none">
                    <path
                      d="M0.5 12.5645H10V11.1527H0.5M10 4.79975H7.28572V0.564453H3.21429V4.79975H0.5L5.25 9.74092L10 4.79975Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </button>
              </div>
              {showFilter && (
                <FilterComponent
                  // className="absolute right-0"
                  onCancel={() => setShowFilter(false)}
                />
              )}
            </span>
          </div>

          <DataTable columns={columnsOrderCompleted} data={data} headerHidden={false} />
        </div>
      </section>
    </>
  )
}

export default RecOrderCompleted

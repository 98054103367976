import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { useTranslation } from 'react-i18next'
import RecTradingBuyBundle from './rec-trading-buy-bundle'

type IRecTradingProps = {
  reccard: { heading: string; image: string; open: boolean }
  className?: string
}
const RecTradingCardBuyer = ({ reccard, className }: IRecTradingProps) => {
  const { t } = useTranslation()
  return (
    <div className="shadow-[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] relative  h-[221px] w-[305px] rounded-[20px] bg-white 2xl:h-[268px] 2xl:w-[336px]">
      <div className="absolute -top-3 right-0 pr-5">
        <div className="flex justify-end gap-4">
          {reccard?.open ? (
            <a
              href="#"
              className="flex h-[25px] w-[25px] cursor-pointer items-center rounded-sm bg-black align-middle leading-[25px] text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 23 18"
                fill="none"
                className="mx-auto"
              >
                <path
                  d="M2 10.7995L7.40885 16.2083L20.7292 2"
                  stroke="white"
                  strokeWidth="2.58333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </a>
          ) : (
            <a
              href="#"
              className="flex h-[25px] w-[25px] cursor-pointer items-center rounded-sm bg-black align-middle leading-[25px] text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                className="mx-auto"
              >
                <path d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z" fill="white"></path>
              </svg>
            </a>
          )}
        </div>
      </div>
      <Dialog>
        <DialogTrigger asChild>
          <div className="flex w-full flex-col px-[15px] py-[15px] font-montserrat 2xl:px-5 2xl:py-5">
            <div className="w-full">
              <div className="flex h-auto gap-[12px] overflow-hidden">
                <div className="h-[74px] w-[102px] flex-none rounded-[10px] 2xl:h-[80px] 2xl:w-[110px]">
                  {/* <img className="block h-auto w-full object-cover " src="/image_green.png" /> */}
                  <img className="block h-auto w-full object-cover " src={reccard?.image} />
                </div>
                <div className="flex flex-col gap-[6px] text-secoundary">
                  <h2 className="text-sm font-bold leading-[17px] text-secoundary 2xl:text-base 2xl:leading-[19.5px]">
                    {reccard?.heading}
                  </h2>
                  <p className="text-ellipsis text-xs font-normal leading-[14.6px] text-secoundary">
                    4895 Texas Ave, Reno, NV 89506, United States.
                  </p>
                </div>
              </div>
            </div>
            <hr className="my-[12px] h-px border-0 bg-[#E0E0E0] 2xl:my-[16px]"></hr>
            <div className="flex w-full flex-col items-center gap-2">
              <div className="flex w-full justify-between text-secoundary">
                <span className="2xl:leading[17px] text-xs font-medium leading-[14.6px] 2xl:text-sm">
                  Energy Produced per Year{' '}
                </span>
                <span className="2xl:leading[17px] text-xs font-bold leading-[14.6px] 2xl:text-sm">7200 MWh</span>
              </div>

              <div className="flex w-full justify-between text-secoundary">
                <span className="2xl:leading[17px] text-xs font-medium leading-[14.6px] 2xl:text-sm">
                  RECs Produced per Yr
                </span>
                <span className="2xl:leading[17px] text-xs font-bold leading-[14.6px] 2xl:text-sm ">74 MW</span>
              </div>
            </div>
            <div className="mt-[12px] 2xl:mt-4">
              <Button className="w-full">Buy RECs</Button>
            </div>
          </div>
        </DialogTrigger>
        <DialogContent className="max-h-5/6 h-auto w-[680px] max-w-[680] overflow-y-auto rounded-[20px] bg-white py-10  pl-10 pr-3">
          <div className="max-h-[calc(100vh_-_230px)] overflow-auto pr-7">
            <form>
              <div className="flex w-full gap-[15px]">
                <div className="aspect-[150/180] flex-none">
                  <img className="block h-auto w-full object-cover " src="/rec-pupupimg.svg" />
                </div>
                <div className="w-full">
                  <div className="flex w-full flex-col">
                    <div className="">
                      <h6 className="text-sm font-medium">Solar Photovoltaic Power Project</h6>
                      <label
                        htmlFor="action"
                        className="flex items-start justify-between gap-[10px] text-lg font-medium leading-6 text-[#333] 2xl:text-xl"
                      >
                        <div>
                          <span className="line-clamp-1 text-base font-bold 2xl:text-lg">Solar Power ARECs</span>
                          <p className="mt-[3px] flex gap-2 text-xs font-normal 2xl:text-sm">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="17"
                              viewBox="0 0 12 17"
                              fill="none"
                            >
                              <path
                                d="M6 2.02381C7.65 2.02381 9 3.39524 9 5.07143C9 6.67143 7.425 9.2619 6 11.0905C4.575 9.18571 3 6.67143 3 5.07143C3 3.39524 4.35 2.02381 6 2.02381ZM6 0.5C3.525 0.5 1.5 2.55714 1.5 5.07143C1.5 8.5 6 13.4524 6 13.4524C6 13.4524 10.5 8.42381 10.5 5.07143C10.5 2.55714 8.475 0.5 6 0.5ZM6 3.54762C5.175 3.54762 4.5 4.23333 4.5 5.07143C4.5 5.90952 5.175 6.59524 6 6.59524C6.825 6.59524 7.5 5.90952 7.5 5.07143C7.5 4.23333 6.825 3.54762 6 3.54762ZM12 13.4524C12 15.1286 9.3 16.5 6 16.5C2.7 16.5 0 15.1286 0 13.4524C0 12.4619 0.9 11.6238 2.325 11.0143L2.775 11.7C2.025 12.081 1.5 12.5381 1.5 13.0714C1.5 14.1381 3.525 14.9762 6 14.9762C8.475 14.9762 10.5 14.1381 10.5 13.0714C10.5 12.5381 9.975 12.081 9.15 11.7L9.6 11.0143C11.1 11.6238 12 12.4619 12 13.4524Z"
                                fill="#333333"
                              />
                            </svg>
                            <span className="line-clamp-1">4894 Gooch Hill Rd, San Jose, CA 94088</span>
                          </p>
                        </div>
                        <span className="flex w-[100px] flex-none flex-shrink-0 flex-grow-0 rounded-[10px] border-[2px] border-[#E0E0E0] px-2 py-[4px] text-center text-sm font-normal">
                          Since 2010
                        </span>
                      </label>
                    </div>
                    <div className="descripton mb-[12px] mt-4">
                      <p className="text-xs 2xl:text-sm">
                        The Fuentes solar farm is a privately owned and feeds its power into the PG&E utility and grid
                        system. It has been ap
                      </p>
                    </div>
                    <div className="flex w-full flex-col text-[#006E9D]">
                      <span className="text-xs  2xl:text-sm">*Tracked by ERCOT </span>
                      <span className="text-xs 2xl:text-sm">*With REgeneration since 2023 </span>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="my-4 h-px border-0 bg-[#E0E0E0]" />
              <div className="flex w-full flex-col rounded-lg">
                <div className="flex flex-col">
                  <div className="mb-[18px] flex w-full items-center justify-between font-medium text-secoundary">
                    <p className=" block text-sm 2xl:text-base">Energy Produced per month</p>
                    <p className=" block text-sm 2xl:text-base">7200 MWh</p>
                  </div>
                  <div className="mb-[18px] flex w-full items-center justify-between font-medium text-secoundary">
                    <p className="text-sm 2xl:text-base">Energy Produced per hr</p>
                    <p className="text-sm 2xl:text-base">74 MWh</p>
                  </div>
                  <div className="flex w-full items-center justify-between font-medium text-secoundary">
                    <p className="text-sm 2xl:text-base">Total Energy Produced</p>
                    <p className="text-sm 2xl:text-base">49,003 MWh</p>
                  </div>
                </div>
              </div>

              <div className="mb-[30px] mt-10 flex w-full flex-col rounded-lg">
                <h5 className="flex w-full py-2 text-base font-bold text-secoundary">Verified Documents</h5>

                <ul className="flex flex-col gap-[13px]">
                  <li className="2xl-text-base flex gap-1 text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M22 10.4952L19.56 7.71516L19.9 4.03516L16.29 3.21516L14.4 0.0351562L11 1.49516L7.6 0.0351562L5.71 3.21516L2.1 4.02516L2.44 7.70516L0 10.4952L2.44 13.2752L2.1 16.9652L5.71 17.7852L7.6 20.9652L11 19.4952L14.4 20.9552L16.29 17.7752L19.9 16.9552L19.56 13.2752L22 10.4952ZM9 15.4952L5 11.4952L6.41 10.0852L9 12.6652L15.59 6.07516L17 7.49516L9 15.4952Z"
                        fill="#00A206"
                      />
                    </svg>
                    <span className="2xl-text-base text-sm leading-[22px]">
                      Renewable Energy Power Purchase Agreement
                    </span>
                  </li>
                  <li className="2xl-text-base flex gap-1 text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M22 10.4952L19.56 7.71516L19.9 4.03516L16.29 3.21516L14.4 0.0351562L11 1.49516L7.6 0.0351562L5.71 3.21516L2.1 4.02516L2.44 7.70516L0 10.4952L2.44 13.2752L2.1 16.9652L5.71 17.7852L7.6 20.9652L11 19.4952L14.4 20.9552L16.29 17.7752L19.9 16.9552L19.56 13.2752L22 10.4952ZM9 15.4952L5 11.4952L6.41 10.0852L9 12.6652L15.59 6.07516L17 7.49516L9 15.4952Z"
                        fill="#00A206"
                      />
                    </svg>
                    <span className="2xl-text-base text-sm leading-[22px]">
                      Real-time connection to power plant SCADA system
                    </span>
                  </li>
                  <li className="2xl-text-base flex gap-1 text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M22 10.4952L19.56 7.71516L19.9 4.03516L16.29 3.21516L14.4 0.0351562L11 1.49516L7.6 0.0351562L5.71 3.21516L2.1 4.02516L2.44 7.70516L0 10.4952L2.44 13.2752L2.1 16.9652L5.71 17.7852L7.6 20.9652L11 19.4952L14.4 20.9552L16.29 17.7752L19.9 16.9552L19.56 13.2752L22 10.4952ZM9 15.4952L5 11.4952L6.41 10.0852L9 12.6652L15.59 6.07516L17 7.49516L9 15.4952Z"
                        fill="#00A206"
                      />
                    </svg>
                    <span className="2xl-text-base text-sm leading-[22px]">
                      Digital Renewable Energy Certificate generation
                    </span>
                  </li>
                  <li className="2xl-text-base flex gap-1 text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M22 10.4952L19.56 7.71516L19.9 4.03516L16.29 3.21516L14.4 0.0351562L11 1.49516L7.6 0.0351562L5.71 3.21516L2.1 4.02516L2.44 7.70516L0 10.4952L2.44 13.2752L2.1 16.9652L5.71 17.7852L7.6 20.9652L11 19.4952L14.4 20.9552L16.29 17.7752L19.9 16.9552L19.56 13.2752L22 10.4952ZM9 15.4952L5 11.4952L6.41 10.0852L9 12.6652L15.59 6.07516L17 7.49516L9 15.4952Z"
                        fill="#00A206"
                      />
                    </svg>
                    <span className="2xl-text-base text-sm leading-[22px]">Project Validation Report</span>
                  </li>
                  <li className="2xl-text-base flex gap-1 text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M22 10.4952L19.56 7.71516L19.9 4.03516L16.29 3.21516L14.4 0.0351562L11 1.49516L7.6 0.0351562L5.71 3.21516L2.1 4.02516L2.44 7.70516L0 10.4952L2.44 13.2752L2.1 16.9652L5.71 17.7852L7.6 20.9652L11 19.4952L14.4 20.9552L16.29 17.7752L19.9 16.9552L19.56 13.2752L22 10.4952ZM9 15.4952L5 11.4952L6.41 10.0852L9 12.6652L15.59 6.07516L17 7.49516L9 15.4952Z"
                        fill="#00A206"
                      />
                    </svg>
                    <span className="2xl-text-base text-sm leading-[22px]">REC Issuance Report</span>
                  </li>
                </ul>
              </div>
            </form>
            <div className="flex w-full gap-2 rounded-lg py-2 text-[#006E9D]">
              <span className="font-base font-bold underline">View Project Registry Details</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 15 15"
                fill="none"
                className="relative top-2"
              >
                <path
                  d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"
                  fill="#006E9D"
                />
              </svg>
            </div>
          </div>
          <div className="mt-5 flex gap-5 pr-7">
            <RecTradingBuyBundle buttonText="Buy Energy" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default RecTradingCardBuyer

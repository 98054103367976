import { Button } from '../ui/button'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import { Input } from '../ui/input'

const RecTradingBuyBundle = ({ buttonText }: { buttonText?: string }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button tabindex="-1">{buttonText || 'Buy Bundled Energy'}</Button>
      </DialogTrigger>
      <DialogContent className="max-h-5/6 h-auto w-full max-w-[660px] overflow-y-auto rounded-[20px] bg-white px-5 py-5">
        <div className="">
          <table className="w-full border-collapse border-spacing-2 text-left font-montserrat text-sm rtl:text-right">
            <thead className="text-sm font-bold 2xl:text-base">
              <tr>
                <th scope="col" className="w-1/4 py-[10px] text-left">
                  Vintage Date
                </th>
                <th scope="col" className="w-1/4 py-[10px]">
                  Available RECs
                </th>
                <th scope="col" className="py-[10px]">
                  Price/REC
                </th>

                <th scope="col" className="w-1/4 py-[10px]">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-sm font-normal 2xl:text-base">
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        tabindex="-1"
                        className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]"
                      >
                        <span>Purchase Now</span>
                      </Button>
                    </DialogTrigger>

                    <DialogContent className="max-h-5/6 h-auto w-[520px] max-w-[520px] overflow-y-auto rounded-[40px] bg-white px-10 py-5">
                      {/* Pop UP Step 3*/}
                      <div className="">
                        <div className="">
                          <p className="py-4 text-center">Enter how many RECs do you want to purchase?</p>
                          <p className="flex justify-center py-5 text-center text-sm font-bold text-secoundary 2xl:text-base">
                            <Input
                              id="recpurchase"
                              autoComplete="REC Purchase"
                              placeholder="REC Purchase"
                              name="recurchase"
                              value="4400.00"
                              spellCheck="false"
                              data-ms-editor="false"
                              className="w-1/2 bg-white text-center "
                            />
                          </p>
                          <div className="mt-5 flex gap-5">
                            <Button variant="secondary">Back</Button>
                            <Button>Buy Now</Button>
                          </div>
                        </div>
                      </div>
                      {/* Pop UP Step 2 End*/}
                    </DialogContent>
                  </Dialog>
                </td>
              </tr>
              <tr className="text-sm font-normal 2xl:text-base">
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        tabindex="-1"
                        className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]"
                      >
                        <span>Purchase Now</span>
                      </Button>
                    </DialogTrigger>

                    <DialogContent className="max-h-5/6 h-auto w-[520px] max-w-[520px] overflow-y-auto rounded-[40px] bg-white px-10 py-5">
                      {/* Pop UP Step 3*/}
                      <div className="">
                        <div className="">
                          <p className="py-4 text-center">Enter how many RECs do you want to purchase?</p>
                          <p className="flex justify-center py-5 text-center text-sm font-bold text-secoundary 2xl:text-base">
                            <Input
                              id="recpurchase"
                              autoComplete="REC Purchase"
                              placeholder="REC Purchase"
                              name="recurchase"
                              value="4400.00"
                              spellCheck="false"
                              data-ms-editor="false"
                              className="w-1/2 bg-white text-center "
                            />
                          </p>
                          <div className="mt-5 flex gap-5">
                            <Button variant="secondary">Back</Button>
                            <Button>Buy Now</Button>
                          </div>
                        </div>
                      </div>
                      {/* Pop UP Step 2 End*/}
                    </DialogContent>
                  </Dialog>
                </td>
              </tr>
              <tr className="text-sm font-normal 2xl:text-base">
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        tabindex="-1"
                        className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]"
                      >
                        <span>Purchase Now</span>
                      </Button>
                    </DialogTrigger>

                    <DialogContent className="max-h-5/6 h-auto w-[520px] max-w-[520px] overflow-y-auto rounded-[40px] bg-white px-10 py-5">
                      {/* Pop UP Step 3*/}
                      <div className="">
                        <div className="">
                          <p className="py-4 text-center">Enter how many RECs do you want to purchase?</p>
                          <p className="flex justify-center py-5 text-center text-sm font-bold text-secoundary 2xl:text-base">
                            <Input
                              id="recpurchase"
                              autoComplete="REC Purchase"
                              placeholder="REC Purchase"
                              name="recurchase"
                              value="4400.00"
                              spellCheck="false"
                              data-ms-editor="false"
                              className="w-1/2 bg-white text-center "
                            />
                          </p>
                          <div className="mt-5 flex gap-5">
                            <Button variant="secondary">Back</Button>
                            <Button>Buy Now</Button>
                          </div>
                        </div>
                      </div>
                      {/* Pop UP Step 2 End*/}
                    </DialogContent>
                  </Dialog>
                </td>
              </tr>
              <tr className="text-sm font-normal 2xl:text-base">
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        tabindex="-1"
                        className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]"
                      >
                        <span>Purchase Now</span>
                      </Button>
                    </DialogTrigger>

                    <DialogContent className="max-h-5/6 h-auto w-[520px] max-w-[520px] overflow-y-auto rounded-[40px] bg-white px-10 py-5">
                      {/* Pop UP Step 3*/}
                      <div className="">
                        <div className="">
                          <p className="py-4 text-center">Enter how many RECs do you want to purchase?</p>
                          <p className="flex justify-center py-5 text-center text-sm font-bold text-secoundary 2xl:text-base">
                            <Input
                              id="recpurchase"
                              autoComplete="REC Purchase"
                              placeholder="REC Purchase"
                              name="recurchase"
                              value="4400.00"
                              spellCheck="false"
                              data-ms-editor="false"
                              className="w-1/2 bg-white text-center "
                            />
                          </p>
                          <div className="mt-5 flex gap-5">
                            <Button variant="secondary">Back</Button>
                            <Button>Buy Now</Button>
                          </div>
                        </div>
                      </div>
                      {/* Pop UP Step 2 End*/}
                    </DialogContent>
                  </Dialog>
                </td>
              </tr>
              <tr className="text-sm font-normal 2xl:text-base">
                <td className="pb-2">2020/07</td>
                <td className="pb-2">2,999</td>
                <td className="pb-2">$ 30</td>

                <td className="pb-2">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        tabindex="-1"
                        className="flex h-[40px] w-[140px] items-center justify-around px-[4px] py-[4px]"
                      >
                        <span>Purchase Now</span>
                      </Button>
                    </DialogTrigger>

                    <DialogContent className="max-h-5/6 h-auto w-[520px] max-w-[520px] overflow-y-auto rounded-[40px] bg-white px-10 py-5">
                      {/* Pop UP Step 3*/}
                      <div className="">
                        <div className="">
                          <p className="py-4 text-center">Enter how many RECs do you want to purchase?</p>
                          <p className="flex justify-center py-5 text-center text-sm font-bold text-secoundary 2xl:text-base">
                            <Input
                              id="recpurchase"
                              autoComplete="REC Purchase"
                              placeholder="REC Purchase"
                              name="recurchase"
                              value="4400.00"
                              spellCheck="false"
                              data-ms-editor="false"
                              className="w-1/2 bg-white text-center "
                            />
                          </p>
                          <div className="mt-5 flex gap-5">
                            <Button variant="secondary">Back</Button>
                            <Button>Buy Now</Button>
                          </div>
                        </div>
                      </div>
                      {/* Pop UP Step 2 End*/}
                    </DialogContent>
                  </Dialog>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default RecTradingBuyBundle

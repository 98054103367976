import { useUserStore } from 'src/_store/user.store'
import React, { useEffect, useState } from 'react'
import { IOrder } from 'src/_models/order.model'
import { WallyMessaging } from 'src/types/types'
import { cn } from 'src/lib/utils'
import { ChevronDown, ChevronUp, XIcon } from 'lucide-react'

const initialMessages: WallyMessaging[] = [
  {
    timestamp: '10:00 AM',
    user_whosend: 'Wally',
    message: 'Hi, I am Wally, ask me anything',
  },
  // more messages...
]

const ChatAuction = ({ order, toogleChat }: { order: IOrder; toogleChat: () => void }) => {
  const { user } = useUserStore((state) => ({ user: state.user }))

  const [messages, setMessages] = useState<WallyMessaging[]>(initialMessages)
  const [minimised, setMinimised] = useState<boolean>(false)
  const [newMessage, setNewMessage] = useState('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value)
  }

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!newMessage.trim()) return

    const user_name =
      (user?.firstName ? user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) : '') +
      ' ' +
      (user?.lastName ? user.lastName.charAt(0).toUpperCase() : '')

    const userMessage: WallyMessaging = {
      timestamp: new Date().toLocaleTimeString(),
      user_whosend: user_name,
      message: newMessage,
    }

    setMessages((prevMessages) => [...prevMessages, userMessage])
    setNewMessage('')

    setNewMessage('')
  }

  return (
    <>
      <div
        style={{ boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);' }}
        className={cn(
          'fixed top-0 -right-[calc(50vw-370.5px)] mr-4 z-50 bg-white rounded-[20px] border-white w-[440px] border-[3.1px] h-[434px] overflow-hidden p-4',
          minimised && 'h-20',
        )}
      >
        <div className="flex flex-row justify-between pb-6">
          <h2 className="font-bold text-2xl tracking-tight">{order.auction.generatedId}</h2>
          <span className="flex flex-row gap-2">
            <span className="p-2 cursor-pointer" onClick={() => setMinimised(!minimised)}>
              {minimised ? <ChevronUp /> : <ChevronDown />}
            </span>
            <span className="p-2 cursor-pointer" onClick={toogleChat}>
              <XIcon />
            </span>
          </span>
        </div>
        {!minimised && (
          <>
            <div className="h-[274px] min-w-full overflow-y-auto hide-scroll">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`flex w-full mt-4 space-x-3 max-w-${
                    msg.user_whosend === 'Wally' ? 'xs' : 'sm ml-auto justify-end'
                  } items-center`}
                >
                  {msg.user_whosend === 'Wally' && (
                    <span className="relative flex gap-2 self-start pt-3">
                      <div className="avtar text-base font-bold text-lightblue">{msg.user_whosend}</div>
                    </span>
                  )}
                  <div className="mt-0">
                    <div
                      className={`p-3 rounded-${
                        msg.user_whosend === 'Wally'
                          ? 'r-lg rounded-b-lg bg-[#0177b5]'
                          : 'l-lg rounded-br-lg bg-lightblue'
                      }`}
                    >
                      <p className={`text-sm ${msg.user_whosend === 'Wally' ? 'text-white' : 'text-darkblue'}`}>
                        {msg.message}
                      </p>
                    </div>
                    {/* Uncomment the following line to show timestamps */}
                    {/* <span className="text-xs text-gray-500 leading-none">{msg.timestamp}</span> */}
                  </div>
                  {msg.user_whosend !== 'Wally' && (
                    <span className="relative flex shrink-0 rounded-full justify-center items-center self-start pt-3">
                      <div className="avtar text-base font-bold text-white pl-2">{msg.user_whosend}</div>
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="flex items-center pt-0 mt-5">
              <form className="flex items-center justify-center w-full space-x-2">
                <input
                  className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                  placeholder="Type your message"
                  value={newMessage}
                  onChange={handleInputChange}
                />
                <button
                  className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white disabled:pointer-events-none disabled:opacity-50 bg-[#0177b5] hover:bg-[#111827E6] hover:text-white h-10 px-4 py-2"
                  onClick={handleSendMessage}
                >
                  Send
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ChatAuction

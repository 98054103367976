import { Button } from 'src/components/ui/button'
import { IAuction, IAuctionSupplier } from 'src/_models/auction.model'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useUserStore } from 'src/_store/user.store'
import { differenceInDays } from 'date-fns'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import CurrencyFormatter from 'src/components/ui/currency-formatter'
import * as React from 'react'
import { FUEL_FEES_CALC, BUYER_FEES_CALC, TAXES_CALC } from 'src/lib/constants'
import { toast, useToast } from 'src/components/ui/use-toast'
import { cn } from 'src/lib/utils'
import AuctionDialogHeader from 'src/components/auction/auction-dialog-header'
import AuctionDialogEstimatedShippingAndShippingRadius from 'src/components/auction/auction-dialog-estimated-shipping-and-shipping-radius'
import { isPastAuction } from 'src/lib/auction.util'
import AuctionDialogFeesCalculation from 'src/components/auction/auction-dialog-fees-calculation'

const SupplierAuctionDialogPurchaseNow = ({
  auction,
  setShowDialog,
}: {
  auction: IAuction
  setShowDialog: Dispatch<SetStateAction<boolean>>
}) => {
  const [total, setTotal] = useState(0)
  const { t } = useTranslation('translation')
  const [confirm, setConfirm] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  const [auctionData, setAuctionData] = useState<IAuctionSupplier | null>(null)
  const {
    isLoading,
    data: responseData,
    error,
  } = useQuery({
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auction: IAuctionSupplier }>(`/api/supplier/auctions/${auction.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (responseData && responseData.data) {
      setAuctionData(responseData.data.auction)
    }
  }, [responseData])

  const cancelAuctionMutation = useMutation({
    mutationFn: (): Promise<AxiosResponse<{ message: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/supplier/auctions/${auction.id}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
    onSuccess: (resp) => {
      toast({
        title: t(resp.data.message),
      })
      setShowDialog(false)
      queryClient.invalidateQueries({
        queryKey: ['supplier-my-auctions'],
      })
    },
    onError: (err) => {
      const error = err as AxiosError<{ message: string }>
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: t(error.response?.data?.message || 'error.somethingWrong'),
      })
    },
  })

  if (isLoading || error || !auctionData) {
    return
  }

  const subTotal = auction?.sellingPricePerUnit * auction.fuelWeight

  const confirmCancelAction = () => {
    cancelAuctionMutation.mutate(undefined, {
      onSuccess: () => {
        toast({
          title: t('Cancelled auction.'),
        })
        setShowDialog(false)
      },
      onError: (e) => {
        const error = e as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error.response?.data?.message || 'error.somethingWrong'),
        })
      },
    })
  }

  return (
    <>
      <div className="mx-auto h-full w-full max-w-4xl overflow-y-auto rounded-[40px] bg-white px-[30px] py-[30px]">
        <div className="text-2xl font-bold text-secoundary">
          <AuctionDialogHeader auction={auctionData} />
          <hr className="my-5 h-px border-0 bg-[#E0E0E0]" />
        </div>
        <div className="max-h-[calc(100vh_-_380px)] overflow-auto pr-1">
          <form>
            <div className="flex w-full gap-[20px]">
              <div className="flex  aspect-[1.25] h-[160px] w-[200px] flex-col items-center justify-center gap-2.5 rounded-lg bg-gray2 text-center">
                {auction.media?.fileUrl && <img src={auction.media?.fileUrl} className="h-full w-full object-cover" />}
              </div>
              <div className="w-full py-[10px]">
                <div className="flex w-full flex-col gap-[10px]">
                  <div className="inline-flex gap-2">
                    <label
                      htmlFor="action"
                      className="text-lg font-bold leading-[19.3px] text-secoundary 2xl:text-xl 2xl:leading-[24.3px] uppercase"
                    >
                      {auctionData.fuelColor.name} {auctionData.fuelType.name}
                    </label>
                    <div className="rounded-[9.46px] bg-[rgb(242,242,242)] px-[10px] py-[5px] text-sm font-bold leading-[17px] text-primary 2xl:text-base 2xl:leading-[19.5px]">
                      {auction.fuelPurityLevel?.purity}
                    </div>
                    <div className="rounded-[9.4px] bg-[#F2994A] px-[10px] py-[5px] text-sm font-bold leading-[17px] 2xl:text-base 2xl:leading-[19.5px]">
                      {t('tradeType.' + auction.tradeType)}
                    </div>
                  </div>
                  <p className="text-sm font-normal 2xl:text-base">by {auctionData.user.companyName}</p>
                  <p className="flex gap-2 text-sm font-normal 2xl:text-base">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="23" viewBox="0 0 17 23" fill="none">
                      <path
                        id="Vector"
                        d="M8.38095 2.59524C10.6857 2.59524 12.5714 4.48095 12.5714 6.78571C12.5714 8.98571 10.3714 12.5476 8.38095 15.0619C6.39048 12.4429 4.19048 8.98571 4.19048 6.78571C4.19048 4.48095 6.07619 2.59524 8.38095 2.59524ZM8.38095 0.5C4.92381 0.5 2.09524 3.32857 2.09524 6.78571C2.09524 11.5 8.38095 18.3095 8.38095 18.3095C8.38095 18.3095 14.6667 11.3952 14.6667 6.78571C14.6667 3.32857 11.8381 0.5 8.38095 0.5ZM8.38095 4.69048C7.22857 4.69048 6.28571 5.63333 6.28571 6.78571C6.28571 7.9381 7.22857 8.88095 8.38095 8.88095C9.53333 8.88095 10.4762 7.9381 10.4762 6.78571C10.4762 5.63333 9.53333 4.69048 8.38095 4.69048ZM16.7619 18.3095C16.7619 20.6143 12.9905 22.5 8.38095 22.5C3.77143 22.5 0 20.6143 0 18.3095C0 16.9476 1.25714 15.7952 3.24762 14.9571L3.87619 15.9C2.82857 16.4238 2.09524 17.0524 2.09524 17.7857C2.09524 19.2524 4.92381 20.4048 8.38095 20.4048C11.8381 20.4048 14.6667 19.2524 14.6667 17.7857C14.6667 17.0524 13.9333 16.4238 12.781 15.9L13.4095 14.9571C15.5048 15.7952 16.7619 16.9476 16.7619 18.3095Z"
                        fill="#333333"
                      />
                    </svg>
                    {`${
                      auctionData.shippingDetail.userAddress.city || ''
                        ? auctionData.shippingDetail.userAddress.city + ', '
                        : ''
                    }${auctionData.shippingDetail.userAddress.state}`}
                  </p>

                  <div className="rating">
                    <div className="flex items-center">
                      <svg width="124" height="23" viewBox="0 0 124 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.62L12 2L9.19 8.62L2 9.24L7.45 13.97L5.82 21L12 17.27Z"
                          fill="#F2C94C"
                        />
                        <path
                          d="M37 17.27L43.18 21L41.54 13.97L47 9.24L39.81 8.62L37 2L34.19 8.62L27 9.24L32.45 13.97L30.82 21L37 17.27Z"
                          fill="#F2C94C"
                        />
                        <path
                          d="M62 17.27L68.18 21L66.54 13.97L72 9.24L64.81 8.62L62 2L59.19 8.62L52 9.24L57.45 13.97L55.82 21L62 17.27Z"
                          fill="#F2C94C"
                        />
                        <path
                          d="M87 17.27L93.18 21L91.54 13.97L97 9.24L89.81 8.62L87 2L84.19 8.62L77 9.24L82.45 13.97L80.82 21L87 17.27Z"
                          fill="#F2C94C"
                        />
                        <path
                          d="M112.258 16.8419L112 16.686L111.742 16.8419L106.574 19.9608L107.937 14.0829L108.005 13.7897L107.778 13.5924L103.22 9.63666L109.233 9.11815L109.533 9.0923L109.65 8.81536L112 3.27966L114.35 8.81536L114.467 9.0923L114.767 9.11815L120.779 9.63654L116.213 13.5921L115.985 13.7897L116.053 14.0836L117.424 19.9596L112.258 16.8419Z"
                          stroke="#F2C94C"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-5 flex justify-between gap-5">
              <div className="flex w-1/2 items-center justify-between rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] px-[20px] py-[17px]">
                <div className="text-sm font-normal 2xl:text-base">Quantity</div>
                <div className="text-lg font-bold 2xl:text-xl">
                  {auctionData.fuelWeight} {t('fuelWeightUnit.' + auctionData.fuelWeightUnit)}
                </div>
              </div>

              <div className="flex w-1/2 items-center justify-between rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] px-[20px] py-[17px]">
                <div className="text-sm font-normal 2xl:text-base">Buying Price</div>
                <div className="text-lg font-bold 2xl:text-xl">
                  <CurrencyFormatter currency={auction.sellingCurrency} amount={subTotal} cents={true} />
                </div>
              </div>
            </div>

            <div className="mt-5 flex w-full flex-col rounded-lg">
              <div className="flex flex-col px-[30px]">
                <AuctionDialogFeesCalculation auction={auction} bidAmount={subTotal} setTotal={setTotal} cents={true} />
                <AuctionDialogEstimatedShippingAndShippingRadius auction={auction} />
              </div>
            </div>
          </form>
        </div>
        <hr className="mt-[10px] h-px border-0 bg-[#E0E0E0]" />

        <div className="flex w-full flex-col rounded-lg py-5">
          <div className="flex flex-col px-[30px]">
            <div className="flex w-full items-center justify-between gap-[10px]">
              <p className="2xl:leading[24.3px] block text-base font-bold leading-[19.5px] text-secoundary 2xl:text-xl">
                Total Payable Amount
              </p>
              <p className="2xl:leading[24.3px] block text-base font-bold leading-[19.5px] text-secoundary 2xl:text-xl">
                <CurrencyFormatter currency={auction.sellingCurrency} amount={total} cents={true} />
              </p>
            </div>
          </div>
        </div>
        {/* Only show the buttons if the auction is not past auction */}
        {!isPastAuction(auctionData) && (
          <>
            {confirm && (
              <div className="mt-0px px-[30px] pb-5 text-sm font-normal 2xl:text-base">
                *Are you sure, you want to Cancel Your Bid for{' '}
                <span className="uppercase">
                  {auctionData.fuelColor.name} {auctionData.fuelType.name}
                </span>{' '}
                {auctionData.fuelPurityLevel.purity} amount of
                <span className="text-base font-bold 2xl:text-lg">
                  <CurrencyFormatter currency={auction.sellingCurrency} amount={total} cents={true} />
                </span>
              </div>
            )}
            <div className="mt-5 flex gap-5">
              {!confirm && (
                <Button onClick={() => setConfirm(true)} tabindex="-1" variant="secondary">
                  Cancel
                </Button>
              )}
              {confirm && <Button onClick={confirmCancelAction}>Confirm Cancel</Button>}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SupplierAuctionDialogPurchaseNow

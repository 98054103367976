import { ColumnDef } from '@tanstack/react-table'

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Orderprogress = {
  id: string
  date: string
  headline: string
  weight: string
  status: 'pending' | 'processing' | 'success' | 'failed'
  amount: string
}
export type ColumnsOrderProgress = {
  id: string
  order_date: string
  order_id: string
  fuel_type: string
  color: string
  purity_level: string
  quantity: string
  status_order: string
  buyer_name: string
  winning_bid: string
  action: string
}

export type ColumnsOrderCompleted = {
  id: string
  order_date: string
  order_id: string
  fuel_type: string
  color: string
  purity_level: string

  price: string
  quantity: string
  buyer_name: string
  bid_amount: string
  action: string
}
export type ColumnsRECTransactionHistory = {
  id: string
  transaction_date: string
  rec_type: string
  quantity: string
  // transaction_type: string
  transaction_id: string
  project_name: string
  amount: string
  action: string
}
export type ColumnsPastAuctions = {
  id: string
  fuel_name: string
  supplier_name: string
  shipment_address: string
  purity_level: string

  color: string
  price: string
  quantity: string
  winning_bid: string
  your_bid: string
  total_bid: string
  action: string
}
export const columns: ColumnDef<Orderprogress>[] = [
  {
    accessorKey: 'date',
    header: 'Date',
  },
  {
    accessorKey: 'headline',
    header: 'Headline',
  },
  {
    accessorKey: 'weight',
    header: 'Weight',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
  },
]

export const columnsOrderProgress: ColumnDef<ColumnsOrderProgress>[] = [
  {
    accessorKey: 'order_date',
    header: 'ORDER DATE',
  },
  {
    accessorKey: 'order_id',
    header: 'ORDER ID',
  },
  {
    accessorKey: 'fuel_type',
    header: 'FUEL TYPE',
  },
  {
    accessorKey: 'color',
    header: 'COLOR',
  },
  {
    accessorKey: 'purity_level',
    header: 'PURITY LEVEL',
  },
  {
    accessorKey: 'quantity',
    header: 'QUANTITY',
  },
  {
    accessorKey: 'status_order',
    header: 'STATUS OF ORDER',
  },
  {
    accessorKey: 'buyer_name',
    header: 'Buyer Name',
  },

  {
    accessorKey: 'winning_bid',
    header: 'Winning Bid',
  },
  {
    accessorKey: 'action',
    header: '',
    cell: ({ row }) => {
      // const payment = row.original

      return (
        <a href="#" className="font-bold text-[#0077B6] underline">
          View Orderr
        </a>
      )
    },
  },
]
export const columnsOrderCompleted: ColumnDef<ColumnsOrderCompleted>[] = [
  {
    accessorKey: 'order_date',
    header: 'ORDER DATE',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('order_date')}</div>
    },
  },
  {
    accessorKey: 'order_id',
    header: 'ORDER ID',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('order_id')}</div>
    },
  },
  {
    accessorKey: 'fuel_type',
    header: 'FUEL TYPE',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('fuel_type')}</div>
    },
  },
  {
    accessorKey: 'color',
    header: 'COLOR',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('color')}</div>
    },
  },
  {
    accessorKey: 'purity_level',
    header: 'PURITY LEVEL',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('purity_level')}</div>
    },
  },

  {
    accessorKey: 'price',
    header: 'Price/kg',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('price')}</div>
    },
  },
  {
    accessorKey: 'quantity',
    header: 'QUANTITY',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('quantity')}</div>
    },
  },
  {
    accessorKey: 'buyer_name',
    header: 'Buyer Name',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('buyer_name')}</div>
    },
  },

  {
    accessorKey: 'bid_amount',
    header: 'Bid Amount',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('bid_amount')}</div>
    },
  },
  {
    accessorKey: 'action',
    header: '',
    cell: ({ row }) => {
      // const payment = row.original

      return <a className="cursor-pointer font-bold text-[#0077B6] underline">View Order</a>
    },
  },
]

export const columnsRECTransactionHistory: ColumnDef<ColumnsRECTransactionHistory>[] = [
  {
    accessorKey: 'transaction_date',
    header: 'Transaction Date',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('transaction_date')}</div>
    },
  },
  {
    accessorKey: 'rec_type',
    header: 'REC Type',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('rec_type')}</div>
    },
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('quantity')}</div>
    },
  },
  // {
  //   accessorKey: 'transaction_type',
  //   header: 'Transaction Type',
  //   cell: ({ row }) => {
  //     return <div className="text-center">{row.renderValue('transaction_type')}</div>
  //   },
  // },
  // {
  //   accessorKey: 'transaction_id',
  //   header: 'Transaction ID',
  //   cell: ({ row }) => {
  //     return <div className="text-center">{row.renderValue('transaction_id')}</div>
  //   },
  // },
  {
    accessorKey: 'project_name',
    header: 'Project Name',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('project_name')}</div>
    },
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('amount')}</div>
    },
  },
  // {
  //   accessorKey: 'action',
  //   header: '',
  // },
]

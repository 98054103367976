import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import RecTradingCardBuyer from 'src/components/auction/rec-trading-card-buyer'

const recTracingJson = [
  { heading: 'Wind Power Bundled Project', image: '/images/image1.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image2.png', open: true },
  { heading: 'Solar Grouped Project', image: '/images/image3.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image4.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image5.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image6.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image7.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image8.png', open: true },
  { heading: 'Wind Farm Project', image: '/images/image1.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image2.png', open: true },
]
export default function BuyerRecMyWatchlist() {
  return (
    <>
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px] flex w-full justify-between">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Watchlist</h2>
          </div>
          <div className="grid gap-5  xl:grid-cols-4 2xl:grid-cols-5">
            {recTracingJson.map((recI, index) => {
              return <RecTradingCardBuyer key={index} reccard={recI} />
            })}
          </div>
        </div>
      </section>
    </>
  )
}

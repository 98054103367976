import React, { useEffect, useMemo, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import axios from 'axios'
import { Toaster } from 'src/components/ui/toaster'
// import { socket } from './web-sockets/socket'
import { useUserStore } from 'src/_store/user.store'
import mixpanel from 'mixpanel-browser'

mixpanel.init('4d07cdb862030bf38de0f7a1076784d9')

// axios.defaults.baseURL = 'http://localhost:3000'
axios.defaults.baseURL = 'https://api-dev.regenenergy.io'

export default function App() {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))
  const [isConnected, setIsConnected] = useState(false)
  const queryClient = useMemo(() => new QueryClient({}), [])

  // const onConnect = () => {
  //   console.log('connected websocket')
  //   setIsConnected(true)
  // }
  //
  // const onDisconnect = () => {
  //   setIsConnected(false)
  // }
  //
  // const onPlatformUpdates = () => {
  //   // TODO:
  // }
  //
  // const onRecentUpdates = () => {
  //   // TODO:
  // }

  useEffect(() => {
    if (!user || !user.id) return

    // Mixpanel Tracking
    mixpanel.identify(user.id.toString())

    // function onFooEvent(value) {
    //   setFooEvents(previous => [...previous, value]);
    // }

    // socket.on('connect', onConnect)
    // socket.on('disconnect', onDisconnect)
    // socket.on('platform-updates', onPlatformUpdates)
    // socket.on(`recent-updates:${user.id}`, onRecentUpdates)
    //
    // return () => {
    //   socket.off('connect', onConnect)
    //   socket.off('disconnect', onDisconnect)
    //   // socket.off('foo', onFooEvent);
    // }
  }, [user])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

import React, { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import ErrorMessage from '../ui/error-message'
import axios from 'axios'
import { useUserStore } from 'src/_store/user.store'
import { ArrowDownIcon, ChevronDown, ChevronUp, XIcon } from 'lucide-react'
import { cn } from 'src/lib/utils'

interface SupportFormValues {
  helpWith: string
  subCategory: string
  query: string
}

const subCategoryOptions: { [key: string]: string[] } = {
  'My Dashboard': ['Transactions', 'Payment Information', 'Tier Status', 'Technical Issues', 'Other Issues'],
  'Fuel Trading': [
    'Auctions',
    'Auction Listing',
    'Orders in Progress',
    'Completed Orders',
    'Transactions',
    'Payments',
    'Updates',
    'Tech Issues',
    'Other Issues',
  ],
  'Green Tracing': ['Projects', 'Project Listing', 'Power Tracing', 'Tech Issues', 'Other Issues'],
  'REC Trading': ['Project Issue', 'REC Purchase', 'Completed Orders', 'Transactions', 'Tech Issues', 'Other Issues'],
  Profile: ['Personal Info', 'Company Info', 'Addresses', 'Insurance', 'Documents', 'Payment Details', 'Password'],
  'General Inquiry': ['Transactions', 'Technical Issues', 'Payments', 'Other Issues'],
}

export const ChatBoxSupport: React.FC<{ toogleChat: (e: MouseEvent<HTMLSpanElement>) => void }> = ({ toogleChat }) => {
  const [newMessage, setNewMessage] = useState('')
  const [minimised, setMinimised] = useState<boolean>(false)
  const [subCategories, setSubCategories] = useState<string[]>([])
  const { user } = useUserStore((state) => ({ user: state.user }))

  const { t } = useTranslation('translation')

  const { handleSubmit, control, watch } = useForm<SupportFormValues>()

  const onSubmit: SubmitHandler<SupportFormValues> = async (data) => {
    // Handle form submission
    const rtToken = '1-14-172d874e9150e131865bf1e525037983'
    const subject = `${data.helpWith} - ${data.subCategory}`
    const content = data.query
    const contentType = 'text/plain'
    const rt_url = 'http://rt.regenenergy.io/REST/2.0/ticket'
    const queue = 'Support'
    const requestor = user?.email

    const requestData = {
      Subject: subject,
      Content: content,
      ContentType: contentType,
      Queue: queue,
      Requestor: requestor,
      Owner: 'root',
    }

    try {
      const response = await axios.post(rt_url, requestData, {
        headers: {
          Authorization: `Bearer ${rtToken}`,
          'Content-Type': 'application/json',
        },
        maxRedirects: 0,
      })
      console.log('Ticket raised successfully')
      // Handle successful ticket creation
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error raising ticket:', error.response.status, error.response.data)
      } else {
        console.error('Error raising ticket:', error)
      }
    }
  }

  const selectedHelpWith = watch('helpWith')

  React.useEffect(() => {
    if (selectedHelpWith) {
      setSubCategories(subCategoryOptions[selectedHelpWith] || [])
    } else {
      setSubCategories([])
    }
  }, [selectedHelpWith])

  return (
    <>
      {minimised ? (
        <div
          onClick={() => setMinimised(!minimised)}
          className="w-52 flex items-center border-[3px] border-primary border-b-0 justify-between text-center line-height relative cursor-pointer text-black rounded-t-lg bg-white px-5 py-2 text-sm font-bold 2xl:py-3 2xl:text-base"
        >
          Raise an Issue
          <span className={cn('cursor-pointer p-1 pr-0')} onClick={toogleChat}>
            <XIcon className="h-4" />
          </span>
        </div>
      ) : (
        <div
          style={{ boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);' }}
          className={cn(
            'bg-white rounded-[20px] border-primary w-[440px] border-[3.1px] h-[634px] overflow-hidden p-4',
            minimised && 'h-20',
          )}
        >
          <div className="flex flex-col h-full">
            <div className="flex flex-row justify-between pb-2 text-black">
              <h2 className="font-bold text-xl tracking-tight">Raise an Issue</h2>
              <span className="flex flex-row gap-2">
                <span className="p-2 cursor-pointer" onClick={() => setMinimised(!minimised)}>
                  {minimised ? <ChevronUp /> : <ChevronDown />}
                </span>
                <span className="p-2 cursor-pointer" onClick={toogleChat}>
                  <XIcon />
                </span>
              </span>
            </div>
            {!minimised && (
              <div className="min-w-full overflow-y-auto hide-scroll">
                <form className="flex flex-col justify-center w-full flex-grow" onSubmit={handleSubmit(onSubmit)}>
                  <div className="pb-5 w-full">
                    <div className="flex items-center justify-between">
                      <label htmlFor="helpWith" className="block text-sm font-bold leading-6">
                        What can we help you with?
                      </label>
                    </div>
                    <div className="mt-2">
                      <Controller
                        control={control}
                        name="helpWith"
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <div className="mt-2">
                            <Select
                              name={field.name}
                              value={field.value ? field.value.toString() : ''}
                              onValueChange={(e) => {
                                if (!e) {
                                  return
                                }
                                field.onChange(e)
                              }}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select an Option" />
                              </SelectTrigger>
                              <SelectContent>
                                {Object.keys(subCategoryOptions).map((option) => (
                                  <SelectItem key={option} value={option}>
                                    {option}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            {fieldState.error?.message && (
                              <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="pb-5 w-full">
                    <div className="flex items-center justify-between">
                      <label htmlFor="subCategory" className="block text-sm font-bold leading-6">
                        Specific Issue
                      </label>
                    </div>
                    <div className="mt-2">
                      <Controller
                        control={control}
                        name="subCategory"
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <div className="mt-2">
                            <Select
                              name={field.name}
                              value={field.value ? field.value.toString() : ''}
                              onValueChange={(e) => {
                                if (!e) {
                                  return
                                }
                                field.onChange(e)
                              }}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select Sub Category" />
                              </SelectTrigger>
                              <SelectContent>
                                {subCategories.map((subCategory) => (
                                  <SelectItem key={subCategory} value={subCategory}>
                                    {subCategory}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            {fieldState.error?.message && (
                              <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="pb-5 w-full flex flex-col flex-grow">
                    <div className="flex items-center justify-between">
                      <label htmlFor="query" className="block text-sm font-bold leading-6">
                        What do you want to ask?
                      </label>
                    </div>
                    <div className="h-full mt-2">
                      <Controller
                        control={control}
                        name="query"
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <div className="mt-2 h-full">
                            <textarea
                              {...field}
                              className="p-2 border rounded w-full resize-none h-[225px]"
                              placeholder="Please type your query here"
                            />
                            {fieldState.error?.message && (
                              <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="inline-flex items-center justify-center rounded-md text-m font-bold text-white disabled:pointer-events-none disabled:opacity-50 bg-[#0177b5] hover:bg-[#111827E6] hover:text-white h-10 px-4 py-2"
                  >
                    Raise Ticket
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

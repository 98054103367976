import React, { ReactNode, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useUserStore } from 'src/_store/user.store'
import { Skeleton } from 'src/components/ui/skeleton'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IUser } from 'src/_models/user.model'
const Protected = ({
  children,
  loadingComponent,
  redirectUrl,
}: {
  children: ReactNode
  loadingComponent?: ReactNode
  redirectUrl?: string
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, setUser } = useUserStore((state) => {
    return {
      user: state.user,
      setUser: state.setUser,
    }
  })

  const { isLoading, isError, data } = useQuery({
    queryKey: ['profile'],
    queryFn: (): Promise<AxiosResponse<IUser>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/auth/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    // refetchOnMount: false,
    retry: false,
    // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
    cacheTime: 0,
  })

  useEffect(() => {
    if (
      (user?.userType === 'BUYER' && location.pathname.indexOf('/supplier') !== -1) ||
      (user?.userType === 'SUPPLIER' && location.pathname.indexOf('/buyer') !== -1)
    ) {
      navigate('/')
    }
  }, [user, location.pathname])

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!data) {
      navigate(redirectUrl ? redirectUrl : '/auth/login')
      return
    }
    if (data) {
      setUser(data.data)
    }
    if (
      data.data?.step === 'ACCOUNT_SETUP' ||
      data.data?.step === 'DOCUMENT_UPLOAD' ||
      data.data?.step === 'OTP_VERIFICATION'
    ) {
      navigate('/auth/signup')
    }
  }, [data])

  if (isLoading) {
    return <>{loadingComponent ? loadingComponent : <Skeleton className="h-20 w-full rounded-full" />}</>
  }

  if (isError) {
    return <Navigate to="/auth/login" replace={true} />
  }

  return children
}
export default Protected

import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'src/lib/utils'
import { PropsWithChildren } from 'react'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'flex justify-center rounded-[15px] bg-primary disabled:bg-[#023047] disabled:text-[#828282] text-white shadow-sm hover:bg-btnhover focus:bg-orange  focus:ring-orange focus:ring-transparent  active:bg-primary active:ring-orange active:ring-2 active:text-white disabled:mix-blend-luminosity disabled:color-[#828282]',
          'default-outline':
            'flex items-center text-darkblue font-bold text-lg py-3 px-4 rounded-lg border-2 border-primary',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        secondary:
          'flex justify-center rounded-[15px] bg-white text-secoundary disabled:bg-[#BDBDBD] disabled:text-[#BDBDBD] disabled:px-5 font-500 hover:bg-[#cccccc] shadow-sm ring-2 ring-primary  focus:ring-orange focus:outline-none active:bg-[#cce4f0] active:ring-orange active:ring-2 active:text-white disabled:mix-blend-luminosity',

        calenderbtn:
          'flex justify-center rounded-[15px] bg-white text-secoundary disabled:bg-[#BDBDBD] disabled:text-[#BDBDBD] font-500 hover:bg-[#cccccc] shadow-sm  focus:ring-orange focus:outline-none active:bg-[#cce4f0] active:ring-orange active:ring-2 active:text-white disabled:mix-blend-luminosity',
        tabbtn:
          'flex border-0 text-left justify-left font-normal 2xl:text-base text-sm 2xl:p-5 disabled:bg-[#023047] disabled:text-[#828282] text-secoundary active:bg-white active:rounded-[0.625rem] active:ring-0 active:text-secoundary active:font-bold focus:bg-white focus:font-bold disabled:mix-blend-luminosity disabled:color-[#828282] focus:shadow-[0px_2px_4px_0px_rgba(0,0,0,0.08), 0px_0px_6px_0px_rgba(0,0,0,0.02)]',
        iconTable: 'flex',
        actionTable: '',
      },

      size: {
        default: 'px-5 py-[12px]  text-sm font-bold leading-[17px] 2xl:py-4 2xl:leading-[20px]',
        bigger: 'px-5 py-5 text-xl font-bold ',
        sm: 'text-sm py-3 px-5 2xl:leading-[17px]',
        tabbtn: 'py-5',
        icon: 'px-4 py-4 disabled:px-4 w-14',
        // platinumSm: 'text-sm font-bold py-[15px] px-[44.5px]',
      },
      expand: {
        full: 'w-full',
        normal: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      expand: 'full',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  tabindex?: string
}

const Button = React.forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  ({ className, variant, size, expand, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ variant, size, expand, className }))} ref={ref} {...props} />
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }

import React from 'react'
import { Header } from '../header'
import { Button } from 'src/components/ui/button'
export const getNoneLayout = (page: React.ReactElement) => page

export const getDefaultLayout = (page: React.ReactElement) => {
  return (
    <div className="min-h-screen w-full overflow-hidden bg-lightblue font-montserrat">
      <Header />
      {page}
    </div>
  )
}

export const getAuthLayout = (page: React.ReactElement) => {
  return (
    <div className="relative min-h-screen overflow-hidden font-montserrat">
      {/* Video backgrounds for different screen sizes */}
      {/* Video for mobile screens */}
      {/* <video
        src="https://your-bucket-name.s3.amazonaws.com/mobile-video.mp4"
        autoPlay
        loop
        muted
        className="sm:hidden absolute inset-0 h-full w-full object-cover" // Only visible on small screens (sm and below)
      > */}
      {/* Fallback content */}
      {/* <p>Your browser does not support the video element.</p>
      </video> */}

      {/* Video for lg screens */}
      <video
        src="https://s3aws-re.s3.amazonaws.com/video_assets/53141-472583449_tiny.mp4"
        autoPlay
        loop
        muted
        className="absolute inset-0 h-full w-full bg-lightblue object-cover lg:block xl:hidden 2xl:hidden"
        style={{
          zIndex: -1,
          pointerEvents: 'none', // Disable pointer events to allow interaction with other elements
        }}
      >
        {/* Fallback content */}
        <p>Your browser does not support the video element.</p>
      </video>

      {/* Video for xl screens */}
      <video
        src="https://s3aws-re.s3.amazonaws.com/video_assets/53141-472583449_small.mp4"
        autoPlay
        loop
        muted
        className="absolute inset-0 hidden h-full w-full bg-lightblue object-cover lg:hidden xl:block 2xl:hidden"
        style={{
          zIndex: -1,
          pointerEvents: 'none', // Disable pointer events to allow interaction with other elements
        }}
      >
        {/* Fallback content */}
        <p>Your browser does not support the video element.</p>
      </video>

      {/* Video for 2xl screens */}
      <video
        src="https://s3aws-re.s3.amazonaws.com/video_assets/53141-472583449_medium.mp4"
        autoPlay
        loop
        muted
        className="absolute inset-0 hidden h-full w-full bg-lightblue object-cover lg:hidden xl:hidden 2xl:block"
        style={{
          zIndex: -1,
          pointerEvents: 'none', // Disable pointer events to allow interaction with other elements
        }}
      >
        {/* Fallback content */}
        <p>Your browser does not support the video element.</p>
      </video>

      {/* Content */}
      <div className="flex flex-col md:flex-row">
        <div className="h-screen min-w-0 flex-1">
          <div className="login-screen flex flex-col pl-36 pt-6 h-full justify-between">
            <div className="relative h-28">
              <a href="/" className="inline-block">
                <img className="bg-transparent object-cover" src={'/regenlogo-blue.svg'} alt="" />
              </a>
            </div>
            {/* change to flex bar for padding in 1440p with less height */}
            {/* <p className="xl:pr-30 2xl:pt-[430px] text-left text-3xl font-bold leading-[3.7rem] text-white lg:pr-24 lg:pt-[430px] xl:pt-[430px] xl:text-4xl xl:leading-[4rem] 2xl:pr-52 2xl:text-6xl 2xl:leading-[5.7rem]"> */}
            <div className="pb-[calc((100vh-550px)/2)]">
              <p className="xl:pr-30 text-left text-3xl font-bold leading-[3.7rem] text-white lg:pr-24 xl:text-4xl xl:leading-[4rem] 2xl:pr-52 2xl:text-6xl 2xl:leading-[5.7rem]">
                Your Trading and Services Platform for the New Fuel Economy
              </p>

              <div className="w-44 pt-4">
                <a href="https://regenenergy.io/" target="_blank" rel="noopener noreferrer">
                  <Button className="flex justify-center bg-white">
                    <span className="font-montserrat text-base font-bold text-black">Learn more</span>
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="h-screen w-[633px] flex-none bg-transparent">{page}</div>
      </div>
    </div>
  )
}

import { useState } from 'react'
import FilterComponent from 'src/components/common/filter'
import { DataTable } from 'src/components/data-table'
import { ColumnDef } from '@tanstack/react-table'

type ColumnsTransactionHistory = {
  id: string
  transaction_date: string
  color: string
  fuel_type: string
  quantity: string
  purity_level: string
  buyer_name: string
  amount: string
  price: string
}

const columnsTransactionHistory: ColumnDef<ColumnsTransactionHistory>[] = [
  {
    accessorKey: 'transaction_date',
    header: 'Transaction Date',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('transaction_date')}</div>
    },
  },
  {
    accessorKey: 'fuel_type',
    header: 'Fuel Type',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('fuel_type')}</div>
    },
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('quantity')}</div>
    },
  },

  {
    accessorKey: 'transaction_type',
    header: 'Transaction Type',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('transaction_type')}</div>
    },
  },
  {
    accessorKey: 'transaction_id',
    header: 'Transaction ID',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('transaction_id')}</div>
    },
  },
  {
    accessorKey: 'supplier_name',
    header: 'Supplier Name',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('supplier_name')}</div>
    },
  },

  {
    accessorKey: 'amount',
    header: 'Amount',
    cell: ({ row }) => {
      return <div className="text-center">{row.renderValue('amount')}</div>
    },
  },
  {
    accessorKey: 'action',
    header: '',
    cell: ({ row }) => {
      return (
        <a
          // onClick={() => setShowOrderStatusModal(true)}
          className="cursor-pointer font-bold text-[#0077B6] underline"
        >
          View&nbsp;Order
        </a>
      )
    },
  },
]

async function getData(): Promise<ColumnsTransactionHistory[]> {
  // Fetch data from your API here.
  return [
    {
      id: '1',
      transaction_date: '12·04·23',
      // order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '85%',
      quantity: '250 kg',

      price: '250 kgs',
      buyer_name: 'Hydrogen Purification Co.',
      amount: '$ 3,000.00',
    },
    {
      id: '2',
      transaction_date: '12·04·23',
      // order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Green',
      purity_level: '99%',
      quantity: '250 kg',

      price: '250 kgs',
      buyer_name: 'Hydrogen Purification Co.',
      amount: '$ 3,000.00',
    },
    {
      id: '3',
      transaction_date: '12·04·23',
      // order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Gray',
      purity_level: '85%',
      quantity: '100 kg',

      price: '100 kgs',
      buyer_name: 'Hydrogen Purification Co.',
      amount: '$ 3,000.00',
    },
    {
      id: '4',
      transaction_date: '12·04·23',
      // order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Gray',
      purity_level: '85%',
      quantity: '100 kg',

      price: '400 kgs',
      buyer_name: 'Hydrogen Purification Co.',
      amount: '$ 3,000.00',
    },
    {
      id: '5',
      transaction_date: '12·04·23',
      // order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      quantity: '250 kg',

      price: '250 kgs',
      buyer_name: 'Hydrogen Purification Co.',
      amount: '$ 3,000.00',
    },
    {
      id: '6',
      transaction_date: '12·04·23',
      // order_id: 'OID 241004IMD508D',
      fuel_type: 'Ammonia',
      color: 'Blue',
      purity_level: '72%',
      quantity: '400 kg',

      price: '250 kgs',
      buyer_name: 'Hydrogen Purification Co.',
      amount: '$ 3,000.00',
    },
  ]
}
const data = await getData()

const RecTransactionHistory = () => {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  return (
    <>
      <section className="w-full">
        <div className="container mx-auto bg-transparent ">
          <div className="mb-[15px] flex w-full items-center justify-between pt-10 align-middle ">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Transaction History</h2>
            <span className="relative text-sm">
              <div className="flex gap-[10px]">
                <button
                  onClick={() => setShowFilter(!showFilter)}
                  className="w-[102px] rounded-[10px] bg-white px-[15px] py-[8px] text-center align-middle text-xs font-medium text-secoundary shadow-md 2xl:text-sm"
                >
                  Filter By
                </button>
                <button className="rounded-[10px] text-center align-middle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 10 13" fill="none">
                    <path
                      d="M0.5 12.5645H10V11.1527H0.5M10 4.79975H7.28572V0.564453H3.21429V4.79975H0.5L5.25 9.74092L10 4.79975Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </button>
              </div>
              {showFilter && (
                <FilterComponent
                  // className="absolute right-0"
                  onCancel={() => setShowFilter(false)}
                />
              )}
            </span>
          </div>

          <DataTable columns={columnsTransactionHistory} data={data} headerHidden={false} />
        </div>
      </section>
    </>
  )
}

export default RecTransactionHistory

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'
import { Label } from '../ui/label'
import { Checkbox } from '../ui/checkbox'

// type IFilterProps = {

//   className?: string
// }
export default function FilterComponent({ onCancel }: { onCancel: () => void }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <div
        data-te-modal-init
        className="z-9990 fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden bg-black/10 outline-none"
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
        onClick={onCancel}
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none  relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
        >
          <div className="pointer-events-auto relative flex w-full flex-col rounded-[20px] border-none bg-white bg-clip-padding text-current shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] outline-none"></div>
        </div>
      </div>
      <div className="absolute right-0   top-10 z-[9991] mx-auto h-auto w-[350px] rounded-[20px] bg-white px-5 py-5">
        <div className="mb-4 mt-2 text-sm font-bold text-[#333]">Plant Type</div>
        <div>
          <Label
            htmlFor="anonymous"
            className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            <Checkbox />
            Wind
          </Label>
          <Label
            htmlFor="anonymous"
            className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            <Checkbox />
            Solar
          </Label>
          <Label
            htmlFor="anonymous"
            className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            <Checkbox />
            Hydroelectric
          </Label>
          <Label
            htmlFor="anonymous"
            className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            <Checkbox />
            Geothermal
          </Label>
          <Label
            htmlFor="anonymous"
            className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            <Checkbox />
            Nuclear
          </Label>
        </div>
        <hr className=" mt-4 h-px border-0 bg-[#E0E0E0]" />

        <div className="mt-2 flex gap-5">
          <div className="basis-1/2">
            <label className="text-sm font-bold">Operational Since</label>
            <Select>
              <SelectTrigger className="h-[38px] w-full">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="1998">1998</SelectItem>
                <SelectItem value="1998">1998</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="basis-1/2">
            <label className="text-sm font-bold">Area Code</label>
            <Input
              disabled={false}
              type="number"
              value="400002"
              className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            />
          </div>
        </div>
        <hr className=" mt-4 h-px border-0 bg-[#E0E0E0]" />
        <div className="mt-2 flex flex-col gap-5">
          <label className="text-sm font-bold">Power Generated (Yearly)</label>
          <div className="flex gap-5">
            <div className="basis-1/2">
              <Input
                disabled={false}
                type="number"
                value="400002"
                className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              />
            </div>
            <div className="basis-1/2">
              <Input
                disabled={false}
                type="number"
                value="400002"
                className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

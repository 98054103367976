import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Orderprogress } from 'src/components/columns'
import GreenTracingCardBuyer from 'src/components/auction/green-tracing-card-buyer'
import 'swiper/css/autoplay'
import { useState } from 'react'

const greenTracingWishlistJson = [
  { heading: 'Wind Power Bundled Project', image: '/images/image1.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image2.png', open: true },
  { heading: 'Solar Grouped Project', image: '/images/image3.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image4.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image5.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image6.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image7.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image8.png', open: true },
  { heading: 'Wind Farm Project', image: '/images/image1.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image2.png', open: true },
]

const greenTracingJson = [
  { heading: 'Wind Power Bundled Project', image: '/images/image1.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image2.png', open: false },
  { heading: 'Solar Grouped Project', image: '/images/image3.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image4.png', open: true },
  { heading: 'Solar Power Project', image: '/images/image5.png', open: false },
  { heading: 'Geothermal Energy Project', image: '/images/image6.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image7.png', open: true },
  { heading: 'Geothermal Energy Project', image: '/images/image8.png', open: false },
  { heading: 'Wind Farm Project', image: '/images/image1.png', open: true },
  { heading: 'Hydroelectric Power Project', image: '/images/image2.png', open: true },
]

async function getData(): Promise<Orderprogress[]> {
  // Fetch data from your API here.
  return [
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    {
      id: '1',
      date: '12:40 PM',
      headline: 'Blue Ammonia P. 85%',
      weight: '250 kgs',
      amount: '$ 3,000.00',
      status: 'pending',
    },
    // ...
  ]
}
const data = await getData()
export default function BuyerGreenTracingDashboardWatchList() {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  return (
    <>
      {/* my watch list start */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px] flex w-full justify-between">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Watchlist</h2>
            {/* <a href="/green-tracing/my-watchlist.tsx" className="text-sm font-bold text-primary">
              View All
            </a> */}
          </div>
          <div className="carousel mb-3 grid gap-5  xl:grid-cols-4 2xl:grid-cols-5">
            {greenTracingWishlistJson.map((greenI, index) => {
              return (
                <div className="relative mt-3">
                  <GreenTracingCardBuyer greencard={greenI} />
                </div>
              )
            })}
          </div>
        </div>
      </section>
      {/* All projects */}
      {/* my auction start */}

      {/* Filter*/}
      {/*<div className="mx-auto h-auto  w-[350px] rounded-[20px] bg-white px-5 py-5">*/}
      {/*  <div className="mb-4 mt-2 text-sm font-bold text-[#333]">Plant Type</div>*/}
      {/*  <div>*/}
      {/*    <Label*/}
      {/*      htmlFor="anonymous"*/}
      {/*      className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"*/}
      {/*    >*/}
      {/*      <Checkbox />*/}
      {/*      Wind*/}
      {/*    </Label>*/}
      {/*    <Label*/}
      {/*      htmlFor="anonymous"*/}
      {/*      className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"*/}
      {/*    >*/}
      {/*      <Checkbox />*/}
      {/*      Solar*/}
      {/*    </Label>*/}
      {/*    <Label*/}
      {/*      htmlFor="anonymous"*/}
      {/*      className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"*/}
      {/*    >*/}
      {/*      <Checkbox />*/}
      {/*      Hydroelectric*/}
      {/*    </Label>*/}
      {/*    <Label*/}
      {/*      htmlFor="anonymous"*/}
      {/*      className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"*/}
      {/*    >*/}
      {/*      <Checkbox />*/}
      {/*      Geothermal*/}
      {/*    </Label>*/}
      {/*    <Label*/}
      {/*      htmlFor="anonymous"*/}
      {/*      className="mb-4 flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"*/}
      {/*    >*/}
      {/*      <Checkbox />*/}
      {/*      Nuclear*/}
      {/*    </Label>*/}
      {/*  </div>*/}
      {/*  <hr className=" mt-4 h-px border-0 bg-[#E0E0E0]" />*/}

      {/*  <div className="mt-2 flex gap-5">*/}
      {/*    <div className="basis-1/2">*/}
      {/*      <label className="text-sm font-bold">Operational Since</label>*/}
      {/*      <Select>*/}
      {/*        <SelectTrigger className="h-[38px] w-full">*/}
      {/*          <SelectValue placeholder="" />*/}
      {/*        </SelectTrigger>*/}
      {/*        <SelectContent>*/}
      {/*          <SelectItem value="1998">1998</SelectItem>*/}
      {/*        </SelectContent>*/}
      {/*      </Select>*/}
      {/*    </div>*/}
      {/*    <div className="basis-1/2">*/}
      {/*      <label className="text-sm font-bold">Area Code</label>*/}
      {/*      <Input*/}
      {/*        disabled={true}*/}
      {/*        type="number"*/}
      {/*        value="400002"*/}
      {/*        className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <hr className=" mt-4 h-px border-0 bg-[#E0E0E0]" />*/}
      {/*  <div className="mt-2 flex flex-col gap-5">*/}
      {/*    <label className="text-sm font-bold">Power Generated (Yearly)</label>*/}
      {/*    <div className="flex gap-5">*/}
      {/*      <div className="basis-1/2">*/}
      {/*        <Input*/}
      {/*          disabled={true}*/}
      {/*          type="number"*/}
      {/*          value="400002"*/}
      {/*          className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="basis-1/2">*/}
      {/*        <Input*/}
      {/*          disabled={true}*/}
      {/*          type="number"*/}
      {/*          value="400002"*/}
      {/*          className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* Filter*/}

      {/* Pop UP Step 1*/}
      {/*<div className="mx-auto h-full w-[680px] max-w-3xl overflow-y-auto rounded-[40px] bg-white px-7 py-7">*/}
      {/*  <form>*/}
      {/*    <div className="flex w-full gap-[15px]">*/}
      {/*      <div className="rounded-base flex w-[200px] flex-col items-center justify-center bg-gray2 text-center"></div>*/}
      {/*      <div className="w-full">*/}
      {/*        <div className="flex w-full flex-col gap-[2px]">*/}
      {/*          <h6>Solar Project</h6>*/}
      {/*          <label*/}
      {/*            htmlFor="action"*/}
      {/*            className="flex items-start justify-between gap-[10px] text-lg font-[700] font-medium leading-6 text-[#333] 2xl:text-xl"*/}
      {/*          >*/}
      {/*            <div>*/}
      {/*              <span className="line-clamp-1">Solar Photovoltaic Power Proje Power Proj</span>*/}
      {/*              <p className="mt-[8px] flex gap-2 text-sm font-normal   2xl:text-sm">*/}
      {/*                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="23" viewBox="0 0 17 23" fill="none">*/}
      {/*                  <rect width="17" height="23" fill="#1E1E1E" />*/}
      {/*                  <g id="Live Purchase now Hover">*/}
      {/*                    <rect x="-258" y="-183" width="719" height="630" rx="34" fill="white" />*/}
      {/*                    <rect x="-258" y="-183" width="719" height="630" rx="34" stroke="white" strokeWidth="12" />*/}
      {/*                    <g id="Frame 5">*/}
      {/*                      <g id="Frame 1000002615">*/}
      {/*                        <g id="Frame 1000002612">*/}
      {/*                          <g id="Frame 33">*/}
      {/*                            <g id="Frame 1000002620">*/}
      {/*                              <path*/}
      {/*                                id="Vector"*/}
      {/*                                d="M8.38095 2.59524C10.6857 2.59524 12.5714 4.48095 12.5714 6.78571C12.5714 8.98571 10.3714 12.5476 8.38095 15.0619C6.39048 12.4429 4.19048 8.98571 4.19048 6.78571C4.19048 4.48095 6.07619 2.59524 8.38095 2.59524ZM8.38095 0.5C4.92381 0.5 2.09524 3.32857 2.09524 6.78571C2.09524 11.5 8.38095 18.3095 8.38095 18.3095C8.38095 18.3095 14.6667 11.3952 14.6667 6.78571C14.6667 3.32857 11.8381 0.5 8.38095 0.5ZM8.38095 4.69048C7.22857 4.69048 6.28571 5.63333 6.28571 6.78571C6.28571 7.9381 7.22857 8.88095 8.38095 8.88095C9.53333 8.88095 10.4762 7.9381 10.4762 6.78571C10.4762 5.63333 9.53333 4.69048 8.38095 4.69048ZM16.7619 18.3095C16.7619 20.6143 12.9905 22.5 8.38095 22.5C3.77143 22.5 0 20.6143 0 18.3095C0 16.9476 1.25714 15.7952 3.24762 14.9571L3.87619 15.9C2.82857 16.4238 2.09524 17.0524 2.09524 17.7857C2.09524 19.2524 4.92381 20.4048 8.38095 20.4048C11.8381 20.4048 14.6667 19.2524 14.6667 17.7857C14.6667 17.0524 13.9333 16.4238 12.781 15.9L13.4095 14.9571C15.5048 15.7952 16.7619 16.9476 16.7619 18.3095Z"*/}
      {/*                                fill="#333333"*/}
      {/*                              />*/}
      {/*                            </g>*/}
      {/*                          </g>*/}
      {/*                        </g>*/}
      {/*                      </g>*/}
      {/*                    </g>*/}
      {/*                  </g>*/}
      {/*                </svg>*/}
      {/*                <span className=" line-clamp-1">*/}
      {/*                  4894 Gooch Hill Rd, Bozeman, MT 59718 4894 Gooch Hill Rd, Bozeman, MT 59718*/}
      {/*                </span>*/}
      {/*              </p>*/}
      {/*            </div>*/}
      {/*            <span className="flex w-[100px] flex-none flex-shrink-0 flex-grow-0 rounded-[10px] border-[2px] border-[#E0E0E0] px-2 py-[4px] text-center text-sm font-normal">*/}
      {/*              Since 1948*/}
      {/*            </span>*/}
      {/*          </label>*/}

      {/*          <div className="descripton  mt-[10px]">*/}
      {/*            <p className="text-sm">*/}
      {/*              These projects contribute significantly to Californias renewable energy goals, providing clean*/}
      {/*              electricity to hundreds of thousands of homes.*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*          <div className="flex w-full gap-2 rounded-lg py-2 text-[#006E9D]">*/}
      {/*            <span className="font-base text-sm">*Accompanied with REgeneration Since 2004 </span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <hr className=" mt-4 h-px border-0 bg-[#E0E0E0]" />*/}
      {/*    <div className="flex w-full flex-col rounded-lg py-5">*/}
      {/*      <div className="flex flex-col">*/}
      {/*        <div className="mb-[10px] flex w-full items-center justify-between font-[500]">*/}
      {/*          <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">Energy Produced per month</p>*/}
      {/*          <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">7200 MWh</p>*/}
      {/*        </div>*/}
      {/*        <div className="mb-[10px] flex w-full items-center justify-between">*/}
      {/*          <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">Energy Produced per hr</p>*/}
      {/*          <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">74 MWh</p>*/}
      {/*        </div>*/}
      {/*        <div className="mb-[10px] flex w-full items-center justify-between">*/}
      {/*          <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">Total Energy Produced</p>*/}
      {/*          <p className=" block text-base font-[500] text-[#333333] 2xl:text-base">49,003 MWh</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="flex w-full flex-col rounded-lg py-2">*/}
      {/*      <h5 className="flex w-full py-2 text-base font-bold text-[#333]">Verified Documents</h5>*/}
      {/*      <ul className="-bg-[4px] list-outside list-image-[url(/bullet.png)] space-y-1 px-6 hover:list-image-[url(/bullet.png)]">*/}
      {/*        <li className="py-[4px]">*/}
      {/*          Renewable Energy Power Purchase Agreement Renewable Energy Power Purchase Agreement*/}
      {/*        </li>*/}
      {/*        <li className="py-[4px]">Real-time connection to power plant SCADA system</li>*/}
      {/*        <li className="py-[4px]">Digital Renewable Energy Certificate generation</li>*/}
      {/*        <li className="py-[4px]">Project Validation Report</li>*/}
      {/*        <li className="py-[4px]">REC Issuance Report</li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </form>*/}
      {/*  <div className="flex w-full gap-2 rounded-lg py-2 text-[#006E9D]">*/}
      {/*    <span className="font-base font-bold underline">View Project Registry Details</span>*/}
      {/*    <svg*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*      width="12"*/}
      {/*      height="12"*/}
      {/*      viewBox="0 0 15 15"*/}
      {/*      fill="none"*/}
      {/*      className="relative top-2"*/}
      {/*    >*/}
      {/*      <path*/}
      {/*        d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"*/}
      {/*        fill="#006E9D"*/}
      {/*      />*/}
      {/*    </svg>*/}
      {/*  </div>*/}
      {/*  <div className="mt-5 flex gap-5">*/}
      {/*    <Button>Buy Bundled Energy</Button>*/}
      {/*    <Button>Buy Green Fuel</Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* Pop UP Step 1 End*/}
      {/* Pop UP Step 2*/}
      {/*<div className="mx-auto h-full w-full max-w-4xl overflow-y-auto rounded-[40px] bg-white px-10 py-5">*/}
      {/*  <div className="">*/}
      {/*    <table className="w-full border-collapse border-spacing-2 text-left font-montserrat text-sm rtl:text-right">*/}
      {/*      <thead className="text-sm font-bold 2xl:text-base">*/}
      {/*        <tr>*/}
      {/*          <th scope="col" className="w-1/4 py-[10px] text-left">*/}
      {/*            Supplier*/}
      {/*          </th>*/}
      {/*          <th scope="col" className="w-1/4 py-[10px]">*/}
      {/*            Fuel Particulars*/}
      {/*          </th>*/}
      {/*          <th scope="col" className="py-[10px]">*/}
      {/*            Quantity*/}
      {/*          </th>*/}
      {/*          <th scope="col" className="py-[10px]">*/}
      {/*            Price*/}
      {/*          </th>*/}
      {/*          <th scope="col" className="w-1/4 py-[10px]">*/}
      {/*            &nbsp;*/}
      {/*          </th>*/}
      {/*        </tr>*/}
      {/*      </thead>*/}
      {/*      <tbody className="">*/}
      {/*        <tr className="text-sm font-normal 2xl:text-base">*/}
      {/*          <td className="pb-2">Methanol Pvt. Ltd.</td>*/}
      {/*          <td className="pb-2">Green Hydrogen | 99%</td>*/}
      {/*          <td className="pb-2">2900 Kg</td>*/}
      {/*          <td className="pb-2">$ 3000</td>*/}
      {/*          <td className="pb-2">*/}
      {/*            <Button className="flex w-[180px] items-center justify-around px-2 py-2">*/}
      {/*              <span>Show Auction</span>*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                width="12"*/}
      {/*                height="12"*/}
      {/*                viewBox="0 0 15 15"*/}
      {/*                fill="none"*/}
      {/*                className="relative top-2"*/}
      {/*              >*/}
      {/*                <path*/}
      {/*                  d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"*/}
      {/*                  fill="#fff"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*            </Button>*/}
      {/*          </td>*/}
      {/*        </tr>*/}
      {/*        <tr className="text-sm font-normal 2xl:text-base">*/}
      {/*          <td className="pb-2">Methanol Pvt. Ltd.</td>*/}
      {/*          <td className="pb-2">Green Hydrogen | 99%</td>*/}
      {/*          <td className="pb-2">2900 Kg</td>*/}
      {/*          <td className="pb-2">$ 3000</td>*/}
      {/*          <td className="pb-2">*/}
      {/*            <Button className="flex w-[180px] items-center justify-around px-2 py-2">*/}
      {/*              <span>Show Auction</span>*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                width="12"*/}
      {/*                height="12"*/}
      {/*                viewBox="0 0 15 15"*/}
      {/*                fill="none"*/}
      {/*                className="relative top-2"*/}
      {/*              >*/}
      {/*                <path*/}
      {/*                  d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"*/}
      {/*                  fill="#fff"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*            </Button>*/}
      {/*          </td>*/}
      {/*        </tr>*/}
      {/*        <tr className="text-sm font-normal 2xl:text-base">*/}
      {/*          <td className="pb-2">Methanol Pvt. Ltd.</td>*/}
      {/*          <td className="pb-2">Green Hydrogen | 99%</td>*/}
      {/*          <td className="pb-2">2900 Kg</td>*/}
      {/*          <td className="pb-2">$ 3000</td>*/}
      {/*          <td className="pb-2">*/}
      {/*            <Button className="flex w-[180px] items-center justify-around px-2 py-2">*/}
      {/*              <span>Show Auction</span>*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                width="12"*/}
      {/*                height="12"*/}
      {/*                viewBox="0 0 15 15"*/}
      {/*                fill="none"*/}
      {/*                className="relative top-2"*/}
      {/*              >*/}
      {/*                <path*/}
      {/*                  d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"*/}
      {/*                  fill="#fff"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*            </Button>*/}
      {/*          </td>*/}
      {/*        </tr>*/}
      {/*        <tr className="text-sm font-normal 2xl:text-base">*/}
      {/*          <td className="pb-2">Methanol Pvt. Ltd.</td>*/}
      {/*          <td className="pb-2">Green Hydrogen | 99%</td>*/}
      {/*          <td className="pb-2">2900 Kg</td>*/}
      {/*          <td className="pb-2">$ 3000</td>*/}
      {/*          <td className="pb-2">*/}
      {/*            <Button className="flex w-[180px] items-center justify-around px-2 py-2">*/}
      {/*              <span>Show Auction</span>*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                width="12"*/}
      {/*                height="12"*/}
      {/*                viewBox="0 0 15 15"*/}
      {/*                fill="none"*/}
      {/*                className="relative top-2"*/}
      {/*              >*/}
      {/*                <path*/}
      {/*                  d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"*/}
      {/*                  fill="#fff"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*            </Button>*/}
      {/*          </td>*/}
      {/*        </tr>*/}
      {/*        <tr className="text-sm font-normal 2xl:text-base">*/}
      {/*          <td className="pb-2">Methanol Pvt. Ltd.</td>*/}
      {/*          <td className="pb-2">Green Hydrogen | 99%</td>*/}
      {/*          <td className="pb-2">2900 Kg</td>*/}
      {/*          <td className="pb-2">$ 3000</td>*/}
      {/*          <td className="pb-2">*/}
      {/*            <Button className="flex w-[180px] items-center justify-around px-2 py-2">*/}
      {/*              <span>Show Auction</span>*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                width="12"*/}
      {/*                height="12"*/}
      {/*                viewBox="0 0 15 15"*/}
      {/*                fill="none"*/}
      {/*                className="relative top-2"*/}
      {/*              >*/}
      {/*                <path*/}
      {/*                  d="M0.105469 13.1955L1.51547 14.6055L12.1055 4.01547L12.1055 10.6055L14.1055 10.6055L14.1055 0.605469L4.10547 0.605468L4.10547 2.60547L10.6955 2.60547L0.105469 13.1955Z"*/}
      {/*                  fill="#fff"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*            </Button>*/}
      {/*          </td>*/}
      {/*        </tr>*/}
      {/*      </tbody>*/}
      {/*    </table>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* Pop UP Step 2 End*/}

      {/* My auction  end */}
      {/* my auction start */}
    </>
  )
}
